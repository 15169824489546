<template>
  <div class="bbox">
    <!-- <van-loading type="spinner" v-if="isShow" />-->
    <van-skeleton v-if="isShow" title :row="3" />
    <!-- <van-pull-refresh style="height: 10rem" v-model="loading" @refresh="onRefresh" v-else> -->
    <div style="position: relative" v-else>
      <div>
        <img style="width: 100%" src="@/static/cf_img_bg.png" alt="" />
      </div>
      <div class="flx menu">
        <div @click="getYear">
          <div class="img">
            <img src="@/static/icon_year.png" alt="" />
          </div>
          <div>年分值</div>
        </div>
        <div @click="getMonth">
          <div class="img">
            <img src="@/static/icon_month.png" alt="" />
          </div>
          <div>月分值</div>
        </div>
        <div @click="getDay">
          <div class="img">
            <img src="@/static/icon_day.png" alt="" />
          </div>
          <div>日分值</div>
        </div>
      </div>
    </div>
    <!-- </van-pull-refresh> -->
  </div>
</template>
<script>
import {
  ref,
  getCurrentInstance,
  onMounted,
  onUpdated,
  provide,
  reactive,
  watch,
  nextTick,
  toRefs,
} from "vue";
// 全页面背景色配置
import { useRoute, useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();
    const useRouteone = useRoute();
    sessionStorage.setItem("organizeId", useRouteone.query.tokens);
    sessionStorage.setItem("userInfo", useRouteone.query.userInfo);
    localStorage.setItem("userInfo", useRouteone.query.userInfo);
    const { proxy } = getCurrentInstance();
    const reactiveParams = reactive({
      isShow: true,
      loading: false,
    });
    // 初始化接口数据
    const DataList = async () => {
      let listSub = await proxy.$Api.get(proxy.$generalApi.getAllData);
      if (listSub.data.code == 200) {
        nextTick(() => {
          reactiveParams.isShow = false;
        });
        localStorage.setItem("dictionaries", JSON.stringify(listSub.data.data));
      } else {
        reactiveParams.isShow = false;
      }
    };
    const onRefresh = async () => {
      await proxy.$Api.get(proxy.$generalApi.getAllDatas).then((res) => {
        reactiveParams.loading = false;

        sessionStorage.setItem("Option", JSON.stringify(res.data.data));
        localStorage.setItem("Option", JSON.stringify(res.data.data));
      });
    };
    const getMonth = () => {
      // router.push({ name: "month" });
      uni.navigateTo({
        url: "/pages/yeskip/index?urls=" + "/month",
      });
    };
    const getYear = () => {
      // router.push({ name: "year" });
      uni.navigateTo({
        url: "/pages/yeskip/index?urls=" + "/year",
      });
    };
    const getDay = () => {
      // router.push({ name: "day" });
      uni.navigateTo({
        url: "/pages/yeskip/index?urls=/day",
      });
    };
    onMounted(() => {
      reactiveParams.loading = true;
      onRefresh();
      DataList();
    });
    // 开始触发事件
    return {
      ...toRefs(reactiveParams),
      router,
      useRouteone,
      getYear,
      getMonth,
      getDay,
      DataList,
      onRefresh,
    };
  },
};
</script>
<style lang="scss" scoped>
.bbox {
  background: #f4f4f4;
}

.top {
  // height: 3.4rem;
  background: #295cc9;
  position: relative;
  // display: flex;
  // align-items: end;
  // vertical-align: bottom;
}

.ab {
  // padding-left: 0.4rem;
  height: 1rem;
  font-size: 0.32rem;
  font-family: Noto Sans S Chinese;
  font-weight: bold;
  color: #ffffff;
  text-align: left;
  position: absolute;
  left: 0;
  bottom: 0;
}

.img {
  width: 0.6rem;
  height: 0.6rem;
}

.img img {
  width: 100%;
  height: 100%;
}

.menu {
  width: 5.4rem;
  // margin: -70px auto;
  position: absolute;
  top: 0.75rem;
  left: 1rem;
}

.menu div div:last-child {
  font-size: 0.27rem;
  font-family: Noto Sans S Chinese;
  font-weight: 400;
  color: #000000;
}
</style>
